.packages{
    position: relative;

    &__header{
        padding: 4rem 0;
        border-bottom: 2px solid var(--color-white);
    }
 
    
  

    &__tag{
        border: 2px solid var(--color-white);
        border-radius: 1rem;
        padding: 0.5rem 2rem;
        margin-right:2rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    &__tags{
        display: flex;
        flex-shrink: 1;
        flex-wrap: wrap;
    }

}