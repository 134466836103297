@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.package{
    position: relative;
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 4rem 0;
    border-bottom: 2px solid var(--color-white);
    overflow-x: clip !important;
    @include respond(tab-port) { 
        grid-template-columns: 1fr;
    }

    &__type{
        // padding: 4rem 0;
        // border-right: 2px solid var(--color-white);
        position: relative;
        @include respond(tab-port) { 
            
            padding-bottom: 4rem;
         
        }
    }
    &__details{
        border-left: 2px solid var(--color-white);
        padding-left: 8rem;
        @include respond(tab-port) { 
            padding-left:0;
            padding-top: 4rem;
            border-top: 2px solid var(--color-white);
            border-left:none;
        }
    }
}

.sticky{
    position: sticky;
    top: 3rem;
    padding-bottom: 8rem;
}

.bottom{
    position: absolute;
    
    bottom: 0;
    @include respond(tab-port) { 
            
        padding-bottom: 4rem;
     
    }
}