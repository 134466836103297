@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';
@import '../../styles/utilities.scss';
@import '../../styles/variables.scss';

.projects{
    overflow-x: clip;
    display: grid;
    border-bottom: solid 2px var(--color-white);
    grid-template-columns: 45% 55%;
    padding: 4rem 0;
    @include respond(phone) { 
        grid-template-columns: 1fr;
        
    }

    &__content{
        border-right: solid 2px var(--color-white);
        padding-right: 8rem;
        position: relative;
        @include respond(phone) { 
            padding: 0;
            border: none;
            text-align: center;
        }
    }

    

    &__items{
        padding-left: 5rem;
        // border-left: solid 2px var(--color-white);
        @include respond(tab-land) { 
            padding-left: 4rem;
        }
        @include respond(phone) { 
            padding: 4rem 0 0 0;
        }
    }

    &__item{
        padding-top: 2rem;
    }

    &__image_box{
        
        width: 100%;
        height: auto;
       

        &__small{
            
            width: 100%;
        }
    }

    &__img{
        width: 100%;
        height: auto;
        object-fit: fill;
        border-radius: 1rem;
    }

    &__desc{
        padding-top: .8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__grid{
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(2,1fr);
        column-gap: 6rem;
        @include respond(tab-port) { 
            grid-template-columns: 1fr;
            // padding: 4rem 0;
        }
        
        &__1{
            justify-self:left;
            padding-top: 2rem;
        }
        &__2{
            justify-self: end;
            padding-top: 2rem;
        }
        &__3{
            justify-self:left;
            padding-top: 2rem;
        }
        &__4{
            justify-self: end;
            padding-top: 2rem;
        }

        
    }


}

.sticky{
    position: sticky;
    top: 3rem;
}