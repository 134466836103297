@import './styles/variables.scss';
@import './styles/mixins.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
    overflow-x: clip !important;
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
    background-color: var(--color-grey);
    // border: solid 2px var(--color-blue);
    // margin: 2rem;
    padding: 4rem;
      @include respond(tab-land) { // width < 1200?
          font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
      }
        
      @include respond(mini-phone) { 
        font-size: 31.25%; //1 rem = 5px, 5/16 = 31.25%
        }
    
      @include respond(tab-port) { // width < 900?
          font-size: 50%; //1 rem = 8px, 8/16 = 50%
      }
    
      @include respond(big-desktop) {
          font-size: 75%; //1rem = 12, 12/16
      }

    scroll-behavior:smooth;
   
  }



  
  body {
    box-sizing: border-box;
    
  }
  
  ul{
    list-style: none;
  }

  a{
    text-decoration: none !important;
    color: inherit !important;
  }
