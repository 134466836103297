@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.details{
    
    
    
    &__client{
        // display: none;
        display: grid;
        grid-template-columns: 60% 40%;
        border-bottom: 2px solid var(--color-white);
        padding: 4rem 0;
        @include respond(tab-land) { 
            grid-template-columns: 1fr;
        }
    }
    &__info{
        // grid-column: 2;
        border-left: 2px solid var(--color-white);
        padding-left: 8rem;
        @include respond(tab-land) { 
           border: none;
           padding-top: 4rem;
           padding-left: 0rem;
        }
    }
    &__detail{
        margin-bottom: 2rem ;
    }
    &__name{
        padding-right: 8rem;
        @include respond(tab-land) { 
            border-bottom: 2px solid var(--color-white);
            padding-bottom: 4rem;
            padding-right: 0;
        }
    }

    &__img_box{
        width: 100%;
        margin-bottom: 4rem;
    }
    &__img{
        width: 100%;
        height: auto;
        object-fit: fill;
        border-radius: 1rem;
    }
    &__project{
        position: relative;
        display: grid;
        grid-template-columns: 40% 60%;
        border-bottom: 2px solid var(--color-white);
        padding: 4rem 0;
        @include respond(tab-port) { 
            grid-template-columns: 1fr;
         }
    }
    &__description{
        
        padding-right: 8rem;
        @include respond(tab-port) { 
            border-bottom: 2px solid var(--color-white);
            padding-bottom: 4rem;
         }
    }
    &__images{
        padding-left: 8rem;
        border-left: 2px solid var(--color-white);
        @include respond(tab-port) { 
            border-left: none;
            padding-left: 0;
            padding-top: 4rem;
         }
    }
    &__item{
        margin-bottom: 10rem;
    }
    &__icon{
        margin-bottom: 3rem;
    }
    &__sticky{
        top: 3rem;
        position: sticky;
    }
}