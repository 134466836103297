@import '../styles/mixins.scss';

.about{
    position: relative;
    overflow-x: clip !important;
}

.loader{
    display: grid;
    grid-template-columns: 60% 40%;
    padding: 4rem 0;
    text-align: center;
    @include respond(tab-land) { 
        grid-template-columns: 1fr;
        
    }
    border-bottom: 2px solid var(--color-white);
    &__image_box{
        width: 100%;
        height: auto;
        object-fit: fill;
        padding-left: 4rem;
        @include respond(tab-land) { 
            padding-left: 0;
            padding-bottom: 4rem;
            width: 50%;
            justify-self: center;
        }
        @include respond(phone) { 
           
            width: 70%;
            
        }
    }
    &__image{
        width: 100%;
        height: auto;
        object-fit: fill;
        border-radius: 1rem;
    }

    &__content{
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: space-between;
     
      @include respond(tab-land) { 
        grid-row: 2;
        text-align: center;
    }
      
    }

    &__tag{
        border: 2px solid var(--color-white);
        border-radius: 1rem;
        padding: 0.5rem 2rem;
        margin-right:2rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    &__tags{
        @include respond(tab-land) { 
            padding-top: 4rem;
            justify-content: center;
            align-items: center;
        }
        display: flex;
        flex-shrink: 1;
        flex-wrap: wrap;
    }
    
}

.values{

    display: grid;
    grid-template-columns: 50% 50%;
    padding: 4rem 0;
    border-bottom: 2px solid var(--color-white);
    @include respond(tab-land) { 
        grid-template-columns: 1fr;
        text-align: center;
     }

    &__values{
        border-left: 2px solid var(--color-white);
        padding-left: 4rem;
        @include respond(tab-land) { 
            text-align: center;
            border-left: none;
            padding-left: 0;
            padding-top: 8rem;
         }
    }

    &__heading{
        padding-right: 4rem;
        @include respond(tab-land) { 
            padding-right: 0;
         }
    }

    &__value{
        display: flex;
        align-items: start;
        margin-bottom: 6rem;
        &:last-child{
            margin-bottom: 0;
        }
        @include respond(tab-land) { 
           flex-direction: column;
           align-items: center;
           justify-content: centers;
         }

        
    }
    &__content{
        padding-left: 4rem;
        @include respond(tab-land) { 
            padding-left: 0;
            padding-top: 2rem;
          }
    }
  
    
}
.sticky{
    position: sticky;
    top: 3rem;
}