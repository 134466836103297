@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';
@import '../../styles/utilities.scss';
@import '../../styles/variables.scss';

.offers{
    overflow-x: clip !important;
    border-top: solid 2px var(--color-white);
    border-bottom: solid 2px var(--color-white);
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding: 4rem 0;
    @include respond(tab-port) { 
        grid-template-columns: 30% 70%;
       
    }
    @include respond(large-phone) { 
        grid-template-columns: 25% 75%;
       
    }
    @include respond(phone) { 
        grid-template-columns: 1fr;
        text-align: center;
    }

    &__content{
        border-right: solid 2px var(--color-white);
        @include respond(tab-port) { 
            
            padding-bottom: 8rem;
        }
        @include respond(phone) { 
            border: none;
           
        }
    }
    &__items{
        padding-left:8rem ;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,1fr);
        row-gap: 4rem;
        column-gap: 8rem;

        @include respond(tab-land) { 
            grid-template-columns: 1fr;
            grid-template-rows: repeat(4,1fr);
            row-gap: 8rem;
            column-gap: 0rem;
        }
        @include respond(tab-port) {
            row-gap: 4rem; 
            padding-left:0rem ;
            justify-self: auto;
            padding-left: 8rem;
        }
        @include respond(phone) { 
            padding: 0;
            justify-self: auto;
            text-align: center;
           
        }
        @include respond(phone) {
            row-gap: 2rem; 
        }
    }
    &__item{
        display: flex;
        @include respond(phone) {
            flex-direction: column;
        }
        // align-items: center;
        // justify-content: center;
    }
    &__desc{

        margin-left: 2rem;
        @include respond(phone) {
            margin-left: 0;
            margin-top: 1rem;
        }
    }

    &__image_box{
        
        // height: auto;
    }

    &__img{
        width: 8rem;
        height: auto;
        @include respond(tab-port) {
            width: 6rem; 
        }
    }
}