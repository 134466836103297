@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';
@import '../../styles/utilities.scss';
@import '../../styles/variables.scss';

.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: clip !important;
  
    border-bottom: 2px solid var(--color-white);
    padding: 4rem 0;
    @include respond(tab-port) { 
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__logo_box{
        width: 20rem;
        @include respond(tab-port) { 
            padding-bottom: 4rem;
        }
    }
    &__logo{
        height: auto;
        width: 100%;
    }
    &__links{
        
            flex-shrink: 1;
            flex-wrap: wrap;
            display: flex;
            justify-content: center;

    }
    &__link{
        font-family: 'Poppins', sans-serif;
        font-size: 2rem;
        font-weight: 300;
        color: var(--color-white) !important;
        text-transform: uppercase;

        padding: 0 2rem;
        @include respond(tab-port) { 
            padding: 1rem;
        }
    }
}