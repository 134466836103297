@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

.button{
    background-color: var(--color-blue);
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    color: var(--color-white);
    font-size: 1.8rem;
    padding: 0.5rem 4rem;
    border-radius: 0.7rem;
    cursor: pointer;
    &__transparent{
        background-color: transparent;
    }
}