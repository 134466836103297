@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.projects{
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 4rem 0;
    border-bottom: 2px solid var(--color-white);
    overflow-x: clip !important;
    @include respond(tab-land) { 
        grid-template-columns: 1fr;
    }

    &__content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include respond(tab-land) { 
            padding-bottom: 4rem;
        }
    }
    &__img_box{
        width: 100%;
      
    }
    &__img{
        width: 100%;
        height: auto;
        object-fit: fill;
        border-radius: 1rem;
    }
}