@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.service{
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 4rem 0;
    border-bottom: solid 2px var(--color-white);
    overflow-x: clip !important;
    @include respond(tab-land) { 
        grid-template-columns: 1fr;
    }

    &__img_box{
        width: 100%;
        height: auto;  
    }

    &__sticky{
        position: sticky;
        top: 3rem;
        @include respond(tab-land) { 
            position: relative;
            top: 0;
            z-index: 0;
        }
    }

    &__img{
        width: 100%;
        height: auto;
        object-fit: fill;
        border-radius: 1rem;
    }

    &__service{
        @include respond(tab-land) { 
            padding-bottom: 4rem;
            border-bottom:solid 2px var(--color-white) ;
        }
    }

    &__info{
        padding-left: 4rem;
        border-left: solid 2px var(--color-white);
        @include respond(tab-land) { 
            border-left: none;
            padding: 4rem 0;
           
        }
    }
}