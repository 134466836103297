// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop
$breakpoint arguement choices:
- small-phone
- phone
- large-phone
- tab-port
- tab-land
- big-desktop
1em = 16px
*/
@mixin respond ($breakpoint) {

    @if $breakpoint == smallest {

      @media only screen and (max-width: 17.5em) { @content };    //250px

    }

    @if $breakpoint == mini { 

      @media only screen and (max-width: 18.75em) { @content };    //300px

    }

    @if $breakpoint == mini-phone {

      @media only screen and (max-width: 21.25em) { @content };    //340px

    }

    @if $breakpoint == smaller-phone {

      @media only screen and (max-width: 23.4375em) { @content };   //375px

    }

    @if $breakpoint == small-phone {

      @media only screen and (max-width: 26.5625em) { @content };    //475px

    }
    
    @if $breakpoint == phone {

        @media only screen and (max-width: 37.5em) { @content };    //600px

    }
    
    @if $breakpoint == large-phone {

        @media only screen and (max-width: 48rem) { @content };    //768px

    }

    @if $breakpoint == tab-port {

        @media only screen and (max-width: 52.5em) { @content };   //840px

    }
    @if $breakpoint == tab-land {

        @media only screen and (max-width: 76em) { @content };    //960px

    }

    @if $breakpoint == laptop {

      @media only screen and (min-width: 73.75em) { @content };    //1180

    }

    @if $breakpoint == desktop {

      @media only screen and (min-width: 87.5em) { @content };    //1400

    }

    @if $breakpoint == big-desktop {

        @media only screen and (min-width: 112.5em) { @content };    //1800

    }
}